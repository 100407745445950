import { ColumnContent, Hero, Image } from "@global";
import {
  isSanityColumnContent,
  isSanityHero,
  isSanityImage,
  isSanityImageWithMeta,
} from "@util/types";
import React from "react";

interface Props {
  blocks: any;
}

const Blocks = ({ blocks }: Props) => {
  if (blocks == null) return null;

  return (
    <section className="blocks-wrapper">
      {blocks?.map((block: any, index: number) => {
        if (isSanityHero(block)) {
          return <Hero data={block} key={index} />;
        }

        if (isSanityImageWithMeta(block)) {
          return <Image data={block} key={index} />;
        }

        if (isSanityColumnContent(block)) {
          return <ColumnContent data={block} key={index} />;
        }

        return <p>No block found</p>;
      })}
    </section>
  );
};

export default Blocks;
